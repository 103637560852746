// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:bf8b1701-6d6f-49a7-a883-56ebc3533505",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_du7m4DEOW",
    "aws_user_pools_web_client_id": "781a5ic7q8rcbg6ber3hpkl8l2",
    "oauth": {
        "domain": "amplifyreactappdemode2f060a-de2f060a-ericdev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.bluespurstest.com/",
        "redirectSignOut": "https://www.bluespurstest.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "amplifyreactappdemo-20190416155712-hostingbucket-ericdev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3l807etqppot1.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "dynamobe6fc682-ericdev",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "apicf8f0aa3",
            "endpoint": "https://ihdx55y0he.execute-api.us-east-1.amazonaws.com/ericdev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
