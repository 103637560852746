import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Amplify, { Auth, Hub, API } from 'aws-amplify';

import awsconfig from './aws-exports';

// retrieve temporary AWS credentials and sign requests
// Auth.configure(awsconfig);
Amplify.configure(awsconfig)

class App extends Component {
  constructor(props) {
    super(props);
    /*
    */
    this.state = {eventsSent: 0, user: null};
  }

  componentDidMount() {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          this.setState({ user: data });
          break;
        case "signOut":
          this.setState({ user: null });
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then(user => this.setState({ user }))
      .catch(() => console.log("Not signed in"));
  }

  handlePost() {
    let init = {
        headers: {},
        body: {
          "firstName": "Eric",
          "lastName": "Tucker"
        }
    }

    API.post(awsconfig.aws_cloud_logic_custom[0].name, '/items', init).then(response => {
      console.log(response)
    }).catch(error => {
      console.log(error.response)
    });
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1 className="App-title">Welcome to React</h1>
        <div>
          <button onClick={() => Auth.federatedSignIn()}>Login</button>
        </div>
        <div>
          <button onClick={() => Auth.signOut()}>Logout {this.state.user != null ? ' ' + this.state.user.getUsername() : ''}</button>
        </div>
        <div>
          <button className="App-button" onClick={this.handlePost}>POST</button>
        </div>
        </header>
      </div>
    );
  }
}

export default App;
